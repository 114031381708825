@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: local('Nunito'), local('Nunito-Italic'),
       url('../fonts/Nunito-VariableFont_wght.ttf') format('truetype');
}

body, html, h1, a, .nav-text {
  font-family: "Nunito", sans-serif;
}

/* Reset styles */
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Navigation styles */
nav {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

nav ul,
nav ul li {
  outline: 0;
}

nav ul li a {
  text-decoration: none;
}

/* Body styles */
.profile-page{
    font-family: "Roboto", sans-serif;
    color: rgb(33, 37, 41);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;

}

/* Main container styles */
main {
  min-height: 100vh;
  width: 100vw;
  z-index: 10;
  margin: 0;
}


/* Main menu styles */
.main-menu {
  background: rgba(34, 45, 100, 255);
  padding-top: 20px;
  font-family: "Roboto", sans-serif;
  position: fixed;
  height:100%;
  z-index: 1000;
  left: 0;
  transition: width 0.5s ease-in-out;
  border-bottom-right-radius: 20px ;
  display: flex;
  flex-direction: column;
}

/* Sidebar content */
.sidebar-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Scrollbar styles */
.sidebar-content {
  scrollbar-width: thin;
  scrollbar-color: rgba(100, 100, 100, 0.8) rgba(34, 45, 100, 0.3);
}

.main-menu.open {
  width: 220px;
  max-width: 100%;
}

.main-menu.open .nav-text {
  display: block;
}

.main-menu:not(.open) + .main-content {
  margin-left: 2% ;
  padding-left:16px;
  width:98.3%;
}

.main-menu:not(.open) {
  width: auto;
  border-bottom-right-radius: 20px;
}

.main-menu:not(.open) .nav-text {
  display: none;
}

.main-menu:not(.open) .nav-icon {
  padding-left: 0.8rem !important;
}

.main-menu:not(.open) .nav-ul {
  padding-left: 0;
  padding-top: 18px;
}

.main-menu:not(.open) .nav-item.active a {
  padding-left: 0;
}

.main-menu:not(.open) .nav-item a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.main-menu:not(.open) + .right-content {
  margin-left: 2%;
}

.main-menu h1 {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 20px 0 30px;
  color: #fff;
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  main {
    height: 100vh;
    width: 100vw;
  }

  .main-content {
    height: 100vh;
    width: 100vw;
    align-items: start !important;
    margin-top: 87px !important;
    margin-left: 120px !important;
  }

  .main-menu:not(.open) + .main-content {
    margin-left: 3% !important;
  }
  .main-menu.open + .main-content {
    margin-left: 150px !important;
    width: 87%;
  }
  .main-menu.open {
    width: 189px;
    height:100%;
  }
  .main-menu.open .nav-ul {
    margin-top: 16px !important;
  }

}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .main-content {
    margin-left: 145px !important;
    width: 100vw;
    height: 160vh;
  }
  .main-menu.open {
    width: 189px;
  }
  .nav-item a {
    margin-left: 1% !important;
  }
  .nav-item.active a {
    padding-left: 8%;
  }
  .main-menu:not(.open) + .main-content {
    margin-left: 2% !important;
  }
  .main-menu.open + .main-content {
    width:91%;
  }
}

@media screen and (min-width: 451px) and (max-width: 600px) {
  main {
    grid-template-columns: 22% 78%;
    height: 100%;
  }
  .main-menu {
    width: 86px;
    height: 200%;
  }
  .right-content {
    width: 175vw !important;
    height: 200vh;
    align-items: start !important;
    margin-top: 80% !important;
  }
  .main-menu h1 {
    font-size: 1.0rem;
  }
  .nav-item a {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 450px) {
  main {
    grid-template-columns: 30% 70%;
    height: 100vh;
    width: 100vw;
  }
  .main-menu {
    width: 108px;
    height: 200%;
  }
  .right-content {
    height: 394vh;
    width: 313vw !important;
    align-items: start !important;
    margin-top: 71% !important;
  }
  .main-menu h1 {
    font-size: 1.0rem;
  }
  .nav-item a {
    font-size: 0.5rem;
  }
}
/* Logo styles */
.logo {
  display: none;
}

/* Navigation item styles */
.nav-item {
  position: relative;
  display: block;
  padding-top:10px;
  text-align:left;
}

.nav-item a {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-size: 1rem;
  padding: 15px 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

/* Navigation item pseudo-elements */
.nav-item b:nth-child(1),
.nav-item b:nth-child(2) {
  position: absolute;
  height: 15px;
  width: 100%;
  background: #fff;
  display: none;
}

.nav-item b:nth-child(1)::before,
.nav-item b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(73, 57, 113);
}

.nav-item b:nth-child(1)::before {
  border-bottom-right-radius: 20px;
}

.nav-item b:nth-child(2)::before {
  border-top-right-radius: 20px;
}

/* Active state styles */
.nav-item.active b:nth-child(1),
.nav-item.active b:nth-child(2) {
  display: block;
}

.nav-item.active a {
  color: #242D67;
  background: rgb(254, 254, 254);
  width:auto;
}


/* Navigation icon styles */
.nav-icon {
  width: 20px;
  height: 20px;
  font-size: 25px;
  text-align: center;
  margin-right: 10px;
  margin-left:10px;
}

.arrow-nav-icon{
  color: #FFFFFF;
  float: right;
  font-size: 23px;
  margin-right: 10px;
}
/* Navigation text styles */
.nav-text {
  display: inline-block;
  width: auto;
  height: auto;
  font-weight: 600;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  padding-right:5px;
}

/* Left content styles */
.right-content {
  background: #f6f7fb;
  min-height: 100vh;
  background-color: #FFFFFF;
  display:flex;
  position: relative;
  margin-top: 1rem;
  margin-left: 42px;
}

/* Logout button styles */

.logout-button {
  background-color: #222d64;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  position: fixed;
  top: 20px;
  right:83px;
  z-index: 1000;
  cursor: pointer;
  margin-top: 8px
}

.logout-button:hover {
  background-color: #493971;
}


.left-content {
  padding-top: 65px;
  padding-left: 0;
  padding-right:0;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 20px;
}

.user-table {
  width: 100%;
  min-width: 5%;
  border-collapse: collapse;
  position: absolute;
  left: 0;
  padding-bottom: 2100px;
  z-index: 0;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  color: #000;
}

.user-table th {
  background-color: #f2f2f2;
}

/*home page nav*/


.full-screen-container {
  display: flex;
  height: 100vh;
  width : 100vw;
}

.navigation-container {
  width: 200px;
  background-color: #333;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
}

.content-container {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  color: #000000;
}

.user-home-container{
  height: 100vh;
  padding: 0;
  position: absolute;
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

}

/*profile picture*/

.profile-photo {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 10px;
  cursor: pointer;
}

.profile-photo-modal {
  height: 200px;
  width: 200px;
}

.profilephoto-input {
  width: 300px;
}

.btn-profile {
  border: 1px solid #000;
  width: 150px;
  color: white;
  height : 37px;
  background-color: #1b2d60f2;
  text-align: center;
  padding: 1px;
}

.profile-username {
  position: fixed;
  top: 20px;
  right: 250px;
  color: #fff;
  margin-left: 10px;
  margin-top: 20px;
}

.user-profile-photo {
  border-radius: 50%;
  width: 50px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}

/*Sticky header on top*/
.sticky-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(34, 45, 100);
  padding: 0px 30px;
  z-index: 1000;
  height: 70px;
  top: 0;
  position: fixed;
}

.title {
  display: block;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 20px 0 20px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.profile-section {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
}

.menu-button {
  background: none;
  border: white;
  cursor: pointer;
  padding: 20px;
  color: white;
  width:  10px;
  height: 100px;
  margin-right: 100px;
  font-size: 30px;
}

.nav-parent {
  position: sticky;
}

/*user-report table*/

.reports-heading {
  font-size: 24px;
  color: #242D67;
  margin-bottom: 0;
  margin-right: 50px;
  padding-right: 50px;
  position: absolute;
  top: 80px;
}

.pagination-wrapper {
  margin-top:20px;
}

.main-content {
  margin-left: 188px;
  margin-top: 87px;
  box-shadow: none !important; /* Remove the box shadow */
  border: none !important; /* Remove the border */
}

.main-menu.open .nav-ul {
  margin-top: 20px;
  margin-left: 20px;
  padding-left: 3px;
}

.allusers-edit-button{
  background-color: #222d64;
  color: white;
  padding: 6px 16px;
  font-size: 16px;
  width:100%;
}

.open-image-container {
  margin-top:-24px;
  padding: 3.5rem;
  background-image: url('/assets/images/ATDXT-Logo-envelope.png');
  background-size: 150px;
  background-position: center;
  background-repeat: no-repeat;
}

.close-image-container {
  margin-top:44px;
  padding: 2rem;
  background-image: url('/assets/images/atmini.jpeg');
  background-size: cover;
  background-position: center;
}

.home-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Vector.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.home-image-container.active {
  background-image: url('/assets/images/blue/Vector.png');
}

.main-menu:not(.open) .home-image-container {
  padding-left: 4rem !important;
}

.activeEmployee-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 7.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.activeEmployee-image-container.active {
  background-image: url('/assets/images/blue/Group 7.png');
}

.main-menu:not(.open) .activeEmployee-image-container {
  padding-left: 4rem !important;
}

.addEmployee-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 8.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.addEmployee-image-container.active {
  background-image: url('/assets/images/blue/Group 8.png') !important;
}

.main-menu:not(.open) .addEmployee-image-container {
  padding-left: 4rem !important;
}

.addHoliday-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 12.png');
  background-size: 22px 27px;
  background-repeat: no-repeat;
  background-position: center;
}

.addHoliday-image-container.active {
  background-image: url('/assets/images/blue/Group 12.png');
}

.main-menu:not(.open) .addHoliday-image-container {
  padding-left: 4rem !important;
}

.downloadsalaryslip-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 9.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.downloadsalaryslip-image-container.active {
  background-image: url('/assets/images/blue/Group 9.png');
}

.main-menu:not(.open) .downloadsalaryslip-image-container {
  padding-left: 4rem !important;
}

.uploadsalaryslip-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Vector-1.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.uploadsalaryslip-image-container.active {
  background-image: url('/assets/images/blue/Vector-1.png');
}

.main-menu:not(.open) .uploadsalaryslip-image-container {
  padding-left: 4rem !important;
}

.leavereports-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group.png');
  background-size: 18px 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.leavereports-image-container.active {
  background-image: url('/assets/images/blue/Group.png');
}

.myreports-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 13.png');
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.myreports-image-container.active {
  background-image: url('/assets/images/blue/Group 13.png');
  background-size: 32px 32px;
}


.main-menu:not(.open) .leavereports-image-container {
  padding-left: 4rem !important;
}

.main-menu:not(.open) .myreports-image-container {
  padding-left: 4rem !important;
}

.exemployees-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group-1.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.exemployees-image-container.active {
  background-image: url('/assets/images/blue/Group-1.png');
}

.main-menu:not(.open) .exemployees-image-container {
  padding-left: 4rem !important;
}

.empapproval-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Vector-2.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.empapproval-image-container.active {
  background-image: url('/assets/images/blue/Vector-2.svg');
}

.main-menu:not(.open) .empapproval-image-container {
  padding-left: 4rem !important;
}
.main-menu:not(.open) .right-arrow-nav-icon {
  color: #FFFFFF;
  font-size: 23px;
  margin-top:10px;
}
.menu-username {
  padding: 8px 12px;
  color: #000;
  font-weight: bold;
}