.holiday-progress-container.alt-style  {
  font-family: Arial, sans-serif;
  background: #FFFFFF;
  text-align: left;
  width: 100%;
  max-width: 680px;
  height: auto;
  margin: 1.8rem auto;
  display: flex;
  flex-direction: column;
}

.holiday-progress-container.default-style {
  font-family: Arial, sans-serif;
  background: #FFFFFF;
  text-align: center;
  width: 100%;
  max-width: 900px;
  height: auto;
  margin: 1.8rem auto;
  display: flex;
  flex-direction: column;
}

.holiday-details-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #242D67;
}

.holiday-progress-container.alt-style .holiday-details-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #242D67;
  margin-left: 15px;
}

@media (max-width: 1200px) {
  .holiday-progress-container.default-style {
    max-width: 680px;
  }

  .holiday-progress-container.alt-style .holiday-details-heading {
    margin-left:0;
    text-align: center;
  }
}

.holiday-progress-content {
  font-family: Arial, sans-serif;
  background: #FFFFFF;
  padding: 20px;
  box-shadow: 4px 7px 21px 1px #00000033;
  border-radius: 20px;
  text-align: left;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.country-progress {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.country-flag {
  display: inline-block;
  width: 50px;
  height: 30px;
  background-size: cover;
  background-position: center;
  margin-right: 8px;
  border-radius: 3px;
  margin-top:20px;
}

.country-details {
  flex: 1;
  margin-left:10px;
  margin-top:20px;
}

.country-name {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #E5E5E5;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-background {
  height: 100%;
  border-radius: 10px;
  background-color: #E5E5E5;
  position: relative;
}

.progress-bar-fill {
  height: 100%;
  background-color: #242D67;
  transition: width 0.3s ease;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-summary {
  display: flex;
  align-items: center;
}

.summary-box {
  width: 20px;
  height: 20px;
  background-color: #242D67;
  margin-right: 5px;
  border-radius: 4px;
}

.summary-text {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.pagination-buttons {
  display: flex;
}

.pagination-button {
  background: none;
  border: none;
  color: #333232;
  cursor: pointer;
  font-size: 2rem;
  margin: 0 0.6rem;
  padding: 0;
}

.pagination-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Specific flag styles */
.country-flag-india {
  background-image: url('/assets/images/flags/cbe-india.png');
}
.country-flag-uae {
  background-image: url('/assets/images/flags/uae.png');
}
.country-flag-drc {
  background-image: url('/assets/images/flags/drc.png');
}
.country-flag-congo {
  background-image: url('/assets/images/flags/congo.png');
}
.country-flag-london {
  background-image: url('/assets/images/flags/london.png');
}
.country-flag-srilanka {
  background-image: url('/assets/images/flags/sri lanka.png');
}
.country-flag-singapore {
  background-image: url('/assets/images/flags/singapore.png');
}

/* Holiday table styles */

.holiday-table-container {
  max-height: 391px;
  overflow-y: auto;
  border: 2px solid #F0F6FF;
  border-radius: 8px;
  background: #FFFFFF;
  text-align: left;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
}

.holiday-list-container{
  max-height: 80%;
  overflow-y: auto;
  border: 2px solid #F0F6FF;
  border-radius: 8px;
  background: #FFFFFF;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.holiday-table {
  width: 100%;
  border-collapse: collapse;
}

.holiday-table thead {
  background-color: #F0F6FF;
}

.holiday-table th {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #191919;
  text-align:center;
  padding: 23px 30px 23px 30px;
}

.holiday-table td {
  text-align:center;
  padding: 23px 30px 23px 37px;
}

.holiday-table thead tr:nth-child(even) {
  background-color: #F0F6FF;
}

.holiday-table tbody tr:nth-child(even) {
  background-color: #FAFCFF;
}

.holiday-table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.holiday-table-container, .holiday-list-container {
  scrollbar-color: #CCE0FF transparent;
}


.holiday-table-container::-webkit-scrollbar,
.holiday-list-container::-webkit-scrollbar {
  width: 6px;
}

.holiday-table-container::-webkit-scrollbar-thumb,
.holiday-list-container::-webkit-scrollbar-thumb {
  background-color: #CCE0FF;
  border-radius: 10px;
}

.holiday-table-container::-webkit-scrollbar-track,
.holiday-list-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.popup {
  max-height: 391px;
  overflow-y: auto;
  border: 2px solid #F0F6FF;
  border-radius: 8px;
  background: #FFFFFF;
  text-align: left;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin-top: -42%;
  padding: 16px;
}

.popup-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 8px;
}

.popup-close-button {
  position: absolute;
  background-color: #222d64;
  color: white;
  border: none;
  border-radius: 7%;
  width: 68px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-content {
    max-height: 391px;
    overflow-y: auto;
    border: 2px solid #F0F6FF;
    border-radius: 8px;
    background: #FFFFFF;
    text-align: left;
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    margin-top: -42%;
    padding: 16px;
}

.pop-button {
    background: #242D67;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
}

.pop-button:hover {
    background-color: #1e255b;
}

.popup-save-button {
  position: absolute;
  background-color: #222d64;
  color: white;
  border: none;
  border-radius: 7%;
  width: 68px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 30%;
}

.popup-table {
  width: 100%;
  border-collapse: collapse;
}

.popup-table th, .popup-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.popup-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.popup-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.holiday-note {
  margin-top: 20px;
  font-size: 14px;
  color: #555;
}

.holiday-note p {
  font-weight: bold;
}

.holiday-note ul {
  padding-left: 20px;
}

.holiday-note li {
  margin-bottom: 10px;
}


.swap-dropdown {
  height: 35px;
  padding: 8px 20px;
  border-radius: 51px;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-family: Arial, sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border: 1px solid #ccc;
  text-align: left;
}

.confirm-button {
  background: linear-gradient(180deg, #2A447D 0%, #4EADF6 100%);
  color: white;
  width:auto;
  height: 35px;
  padding: 8px 20px;
  border-radius: 51px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.confirm-button:focus {
  outline: none;
  box-shadow: 0 0 3px #007bff;
}

.popover-body {
  padding: 0;
}

.table {
  width: 100%;
}

.table th, .table td {
  padding: 8px;
  text-align: left;
}

.swap-dropdown .arrow {
  font-size: 12px;
  margin-left: 5px;
}

.swap-dropdown:focus {
  outline: none;
  box-shadow: 0 0 3px #007bff;
}
